jQuery.noConflict();
(($) => {
    if ($('.c-hero-slider__carousel').length > 0) {

        $('.c-hero-slider__carousel').on('afterChange init', function(slick){
            $(slick.currentTarget).find('.slick-active [data-animation]').each(function(){
                animateCSS($(this), $(this).data('animation'));
            });
        });

        $('.c-hero-slider__carousel').on('beforeChange', function(slick){
            $(slick.currentTarget).find('[data-animation]').each(function(){
                $(this).addClass('is-animated');
            });
        });

        $('.c-hero-slider__carousel').slick();
    }
    $('.c-hero-slider__slider-button').on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        $(this).closest('.c-hero-slider').find('.c-hero-slider__carousel').slick($(this).data('direction'));
    });

    function animateCSS(element, animationName) {
        const node = $(element);
        node.removeClass('is-animated')
            .addClass('animated')
            .addClass(animationName);

        function handleAnimationEnd() {
            node.removeClass('animated')
                .removeClass(animationName)
            node.off('animationend', handleAnimationEnd)
        }
    
        node.on('animationend', handleAnimationEnd)
    }
    
})(jQuery);
